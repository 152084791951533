import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';

export default class FullscreenLightbox extends Module {
  static ITEM_SELECTOR = '[data-role="lightbox-item"]';
  static MAXIMUM_IMAGE_DIMENSION = 2000;

  setup() {
    this.element.querySelectorAll(`${this.constructor.ITEM_SELECTOR}`).forEach(item => {
      const imageElement = item.querySelector('img');
      const image = new Image();

      if (!imageElement) { return; }

      image.onload = (event) => {
        const dimensions = this._detectImageDimensions(image);

        item.dataset['pswpWidth'] = dimensions.width;
        item.dataset['pswpHeight'] = dimensions.height;
      };
      image.src = imageElement.src;
    });

    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: this.constructor.ITEM_SELECTOR,
      pswpModule: PhotoSwipe,
      padding: { top: 40, bottom: 40 },
    });

    // Provide information for custom HTML content to allow proper display
    // Provide caption HTML
    this.lightbox.addFilter('itemData', (itemData, index) => {
      let data = itemData;

      if (itemData.element && itemData.element.getAttribute('data-lightbox-item-type') === 'html') {
        const width = parseInt(itemData.element.getAttribute('data-lightbox-item-width'), 10);
        const height = parseInt(itemData.element.getAttribute('data-lightbox-item-height'), 10);
        data = Object.assign(data, {
          type: 'html',
          html: itemData.element.innerHTML,
          width: width,
          height: height,
          w: width,
          h: height,
        });
      }

      return Object.assign(data, {
        caption: itemData.element.getAttribute('data-lightbox-item-caption'),
      });
    });

    // Make sure videos stop playing when item is left (by sliding to next/previous or closing lightbox)
    this.lightbox.on('contentDeactivate', (event) => {
      const itemData = event.content.data;
      if (itemData.type === 'html') {
        event.content.element.innerHTML = '';
        window.setTimeout(() => { event.content.element.innerHTML = itemData.html }, 1);
      }
    });

    // Caption
    this.lightbox.on('uiRegister', () => {
      this.lightbox.pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: '',
        onInit: (captionElement, pswp) => {
          pswp.on('change', () => {
            const itemData = pswp.currSlide.data;
            const captionHTML = itemData.caption || '';

            if (captionHTML.trim().length === 0) {
              captionElement.style.transform = 'translateY(100%)';
            } else {
              captionElement.style.transform = 'translateY(0)';
              captionElement.innerHTML = captionHTML;
            }
          });
        }
      });
    });

    this.bind();
  }

  bind() {
    this.eventApi = new EventAPI();
    this.lightbox.init();
    this.eventApi.on(document.body, 'click', '[data-action="open-fullscreen-lightbox"]', event => {
      event.preventDefault();
      const selector = event.target.getAttribute('href');

      if (document.querySelector(selector) === this.element) {
        this.lightbox.loadAndOpen(0, { gallery: this.element });
      }
    });
  }

  unbind() {
    this.eventApi.off(document.body, 'click', '[data-action="open-fullscreen-lightbox"]');
    this.lightbox.destroy();
  }

  destroy() {
    this.unbind();
  }

  _detectImageDimensions(image) {
    const maximumImageDimension = this.constructor.MAXIMUM_IMAGE_DIMENSION;
    let width = image.naturalWidth;
    let height = image.naturalHeight;

    if (width > height) {
      return {
        width: maximumImageDimension,
        height: Math.round(height/width * maximumImageDimension),
      };
    } else {
      return {
        width: Math.round(width/height * maximumImageDimension),
        height: maximumImageDimension,
      }
    }
  }
}
